import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeId: 0,
};
export const betslipSlice = createSlice({
  name: 'betslip',
  initialState,
  reducers: {
    setBetslipActiveId: (state, action) => {
      const activeId = action.payload;
      state.activeId = activeId;
    },
  },
});

export const betslipReducer = betslipSlice.reducer;
export const { setBetslipActiveId } = betslipSlice.actions;

export const setBetslipToPlaceBets = () => (dispatch) => {
  dispatch(setBetslipActiveId(0));
};

export const setBetslipToOpenBets = () => (dispatch) => {
  dispatch(setBetslipActiveId(1));
};

export const setBetslipToMyMarkets = () => (dispatch) => {
  dispatch(setBetslipActiveId(2));
};
