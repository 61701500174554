import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import { sseMarkets } from 'middleware/sse-markets';
import { sseOrders } from 'middleware/sse-orders';
import { socketMiddleware, Ws } from 'middleware/webSocket';
import { betsReducer } from 'slices/bets';
import { ordersReducer } from 'slices/orders';
import { sportsReducer } from 'slices/sports';
import { userReducer } from 'slices/user';
import { betslipReducer } from 'slices/betslip';
import { localsReducer } from 'slices/locals';
import { searchReducer } from 'slices/search';
import { layoutReducer } from 'slices/layout';
import { configReducer } from 'slices/config';
import { wsReducer } from 'slices/ws';

const reducer = {
  sports: sportsReducer,
  bets: betsReducer,
  user: userReducer,
  orders: ordersReducer,
  betslip: betslipReducer,
  locals: localsReducer,
  search: searchReducer,
  layout: layoutReducer,
  config: configReducer,
  websocket: wsReducer,
};
const middleware = [
  ...getDefaultMiddleware(),
  sseMarkets(),
  sseOrders(),
  socketMiddleware(new Ws()),
];

export const store = configureStore({
  reducer,
  middleware,
});
