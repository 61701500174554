import { createSlice } from '@reduxjs/toolkit';

import Api from 'api';
import { httpRqErrorParser } from 'utils/formatters';
import { updateBetData } from './bets';

import { sseOrdersConnect } from './sse';

export const ORDERS_TYPES = {
  LAY: 'LAY',
  BACK: 'BACK',
};

export const ORDER_MODIFICATION_PROP = {
  edit: 0,
  odds: 1,
  stake: 2,
  persistency: 3,
};

export const createOrder = async (bet, dispatch) => {
  const {
    selectionId,
    price,
    stake,
    betType,
    topMarketIdentifier: marketId,
    handicap,
  } = bet;

  try {
    const { data } = await Api.post(`order/create`, {
      side: betType,
      selectionId,
      price,
      size: stake,
      marketId,
      handicap,
    });
    dispatch(updateBetData(data, bet));
  } catch (e) {
    dispatch(updateBetData(httpRqErrorParser(e), bet));
  }
};

export const createOrders = (bets, dispatch) => {
  return Promise.all(bets.map((bet) => createOrder(bet, dispatch)));
};

const initialState = {
  data: [],
  modifiedOrders: {},
  cashouts: [],
  processingOrders: true,
  processingCashouts: true,
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    addOrders: (state, action) => {
      state.data = action.payload;
      state.processingOrders = false;
    },
    setOrderInProcess: (state, action) => {
      state.processingOrders = action.payload;
    },
    setCashoutsInProcess: (state, action) => {
      state.processingCashouts = action.payload;
    },
    modifyOrder: (state, action) => {
      const { order, modificationProp, newValue } = action.payload;
      /* if (
        Object.keys(order.actions).filter(
          (theaction) => order.actions[theaction] === true
        ).length
      ) {
        state.modifiedOrders[order.betIdentifier] = order;
      } else {
        delete state.modifiedOrders[order.betIdentifier];
      } */
      const mOrder = state.modifiedOrders[order.betIdentifier] || {
        order,
        data: { ...order },
        actions: {},
      };

      if (modificationProp === ORDER_MODIFICATION_PROP.edit) {
        mOrder.editBetMode = newValue;
      }

      if (modificationProp === ORDER_MODIFICATION_PROP.odds) {
        if (Number(newValue) !== Number(order.odds)) {
          mOrder.oddsChanged = true;
          mOrder.actions.updateOdds = true;
        } else {
          mOrder.oddsChanged = false;
          mOrder.actions.updateOdds = false;
        }
        mOrder.data.odds = newValue;
      }

      if (modificationProp === ORDER_MODIFICATION_PROP.stake) {
        if (Number(newValue) !== Number(order.stake)) {
          mOrder.stakeChanged = true;
          mOrder.actions.updateStake = true;
        } else {
          mOrder.stakeChanged = false;
          mOrder.actions.updateStake = false;
        }
        mOrder.data.stake = newValue;
      }

      if (modificationProp === ORDER_MODIFICATION_PROP.persistency) {
        if (newValue !== (order.persistenceType !== 'LAPSE')) {
          mOrder.persistencyChanged = true;
          mOrder.actions.updatePers = true;
        } else {
          mOrder.persistencyChanged = false;
          mOrder.actions.updatePers = false;
        }
        mOrder.data.persistenceType = newValue ? 'PERSIST' : 'LAPSE';
      }

      if (
        mOrder.oddsChanged ||
        mOrder.stakeChanged ||
        mOrder.persistencyChanged ||
        mOrder.editBetMode
      ) {
        state.modifiedOrders[order.betIdentifier] = mOrder;
      } else {
        delete state.modifiedOrders[order.betIdentifier];
      }
    },
    clearModifiedOrderById: (state, action) => {
      delete state.modifiedOrders[action.payload];
    },
    clearModifiedOrders: (state) => {
      state.modifiedOrders = {};
    },
    calculateCashouts: (state, action) => {
      const orders = action.payload;
      const ordersProcessed = {};
      const cashouts = [];
      orders
        .filter((order) => order.sizeRemaining === 0)
        .forEach((order) => {
          if (ordersProcessed[order.marketIdentifier]) {
            if (
              ordersProcessed[order.marketIdentifier][
                `${order.selectionIdentifier}-${order.type}`
              ]
            ) {
              ordersProcessed[order.marketIdentifier][
                `${order.selectionIdentifier}-${order.type}`
              ].push(order);
            } else {
              ordersProcessed[order.marketIdentifier][
                `${order.selectionIdentifier}-${order.type}`
              ] = [];
              ordersProcessed[order.marketIdentifier][
                `${order.selectionIdentifier}-${order.type}`
              ].push(order);
            }
          } else {
            ordersProcessed[order.marketIdentifier] = {};
            ordersProcessed[order.marketIdentifier][
              `${order.selectionIdentifier}-${order.type}`
            ] = [];
            ordersProcessed[order.marketIdentifier][
              `${order.selectionIdentifier}-${order.type}`
            ].push(order);
          }
        });

      Object.keys(ordersProcessed).forEach((market) => {
        if (Object.keys(ordersProcessed[market]).length === 1) {
          const cashout =
            ordersProcessed[market][Object.keys(ordersProcessed[market])[0]];
          const sample = Array.isArray(cashout) ? cashout[0] : null;
          if (sample) {
            cashouts.push({
              orders: cashout,
              marketIdentifier: sample.marketIdentifier,
              type: sample.type,
              selectionIdentifier: sample.selectionIdentifier,
              marketId: sample.marketId,
              inPlay: sample.inPlay,
              marketName: sample.market,
              eventId: sample.eventId,
              event: sample.event,
              sportId: sample.sportId,
            });
          }
        }
      });

      state.cashouts = cashouts;
      state.processingCashouts = false;
    },
  },
});

export const {
  addOrders,
  setOrderInProcess,
  setCashoutsInProcess,
  modifyOrder,
  clearModifiedOrders,
  clearModifiedOrderById,
  calculateCashouts,
} = ordersSlice.actions;
export const ordersReducer = ordersSlice.reducer;

export const getOrders = () => async (dispatch, getState) => {
  dispatch(setOrderInProcess(true));
  dispatch(setCashoutsInProcess(true));
  const { data } = await Api.get(`order/openOrders`);
  const unmatched = data.filter(
    (el) =>
      el.sizeRemaining > 0 ||
      (el.sizeRemaining === 0 &&
        el.sizeMatched === 0 &&
        el.sizeCancelled === 0 &&
        el.sizeLapsed === 0 &&
        el.sizeVoided === 0)
  );
  const eventsIds = unmatched.map((el) => {
    return el.marketIdentifier;
  });
  if (JSON.stringify(getState?.()?.orders?.data) !== JSON.stringify(data)) {
    dispatch(sseOrdersConnect('sse-orders', eventsIds));
  }
  dispatch(addOrders(data));
  dispatch(calculateCashouts(data));
};
