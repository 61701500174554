import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  mappedEvents: [],
  liveTrackerData: {},
};

export const wsSlice = createSlice({
  name: 'websocket',
  initialState,
  reducers: {
    setMappedEvents: (state, action) => {
      state.mappedEvents = action.payload;
    },
    setLiveTrackerData: (state, action) => {
      state.liveTrackerData = action.payload;
    },
  },
});

export const { setMappedEvents, setLiveTrackerData } = wsSlice.actions;

export const wsReducer = wsSlice.reducer;
