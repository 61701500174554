import Api from 'api';
import { sseOrdersConnected, sseOrdersDisconnected } from 'slices/sse';
import { fetchUserBalanceAndLiability } from 'slices/user';
import { getOrders } from 'slices/orders';

const updateData = (data, store) => {
  store.dispatch(getOrders());
  store.dispatch(fetchUserBalanceAndLiability());
};

export const sseOrders = () => {
  let socket = null;

  const onOpen = (store) => (event) => {
    store.dispatch(sseOrdersConnected(event.target.url));
  };

  const onClose = (store) => (event) => {
    store.dispatch(sseOrdersDisconnected(event.target.url));
  };

  const onMessage = (store, data) => {
    updateData(data, store);
  };

  // eslint-disable-next-line consistent-return
  return (store) => (next) => (action) => {
    const { currencyCode } = store.getState().user;
    switch (action.type) {
      case 'SSE_ORDERS_CONNECT':
        if (socket !== null) {
          socket.close();
        }

        socket = new EventSource(
          `${Api.defaults.baseURL}/${
            action.host
          }?currency=${currencyCode}&markets=${action.eventIds.join(',')}`
        );
        /* 
        TotalMatched - display sum near Link to Video
        Status - Open(not disabled)
        Runners Array
          0 - First Team
          1 - Second Team
          2 - Draw
  
            Prices
            AavailableToBack(blue)[0]
              AvailableToLay(red)[0]
                Size - Sum
                Price - Rate
        */

        socket.onmessage = (e) => onMessage(store, JSON.parse(e.data));
        socket.onclose = onClose(store);
        socket.onopen = onOpen(store);
        break;
      case 'SSE_ORDERS_DISCONNECT':
        if (socket !== null) {
          socket.close();
        }
        socket = null;
        break;
      default:
        return next(action);
    }
  };
};

export const sseOrdersMiddleware = sseOrders();
