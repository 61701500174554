import { URL_TYPE, URL_TIME } from 'appConfiguration';

export const getPathnameArray = (pathname) => {
  const arr = pathname.split('/');
  arr.shift();

  return arr;
};

export const isInLiveContext = (pathname) => {
  const [
    urlTime,
    // urlType,
    // sportId,
    // categoryId,
    // tournamentId,
    // eventId,
    // marketId,
    // allParam,
    // page,
    // pageSize,
  ] = getPathnameArray(pathname);

  if (Number(urlTime) === 1) {
    return true;
  }
  return false;
};

export const getLivePrefix = (pathname) => {
  if (isInLiveContext(pathname)) {
    return URL_TIME.LIVE.menuEndpointString;
  }
  return '';
};

export const isInTimeContext = (pathname) => {
  const [
    urlTime,
    // urlType,
    // sportId,
    // categoryId,
    // tournamentId,
    // eventId,
    // marketId,
    // allParam,
    // page,
    // pageSize,
  ] = getPathnameArray(pathname);

  if (Number(urlTime) > 1) {
    return true;
  }

  return false;
};

/*
 *
 * MENU ENDPOINTS (SportsListEndpoint)
 *
 * /Menu/GetMenuCache/{top}
 * /Menu/GetInPlayEventsCache
 * /Menu/topSports - getMenuEndpoint
 * /Menu/topSports/live - getMenuEndpoint
 * /Menu/{urlType}/{id}/all - getSportsListEndpoint
 * /Menu/{urlType}/{id}/all/live - getSportsListEndpoint
 * /Menu/Event/{id}/Market/{mid} - getSportsListEndpoint
 * /Menu/Event/{id}/Market/{mid}/live - getSportsListEndpoint
 * /Menu/{urlType}/{id} - getSportsListEndpoint
 * /Menu/{urlType}/{id}/live - getSportsListEndpoint
 * /Menu/Antepost/{sportId}/{catId}/{evtId}/{marketIdentifier} - getSportsListEndpoint
 * /Menu/search
 *
 */

export const getSportsListEndpoint = (pathname) => {
  const [
    urlTime,
    urlType,
    sportId,
    categoryId,
    tournamentId,
    eventId,
    marketId,
    allParam,
    // page,
    // pageSize,
  ] = getPathnameArray(pathname);

  const allSuffix = Number(allParam) ? '/all' : '';
  const liveSuffix =
    Number(urlTime) === URL_TIME.LIVE.id
      ? `/${URL_TIME.LIVE.menuEndpointString}`
      : '';
  let result = '';
  switch (Number(urlType)) {
    case URL_TYPE.Sport.id:
      result = `/Menu/Sport/${sportId}${allSuffix}${liveSuffix}`;
      break;
    case URL_TYPE.Category.id:
      result = `/Menu/Category/${categoryId}${allSuffix}${liveSuffix}`;
      break;
    case URL_TYPE.Tornament.id:
      result = `/Menu/Tournament/${tournamentId}${allSuffix}${liveSuffix}`;
      break;
    case URL_TYPE.TodaysCard.id:
      result = `/Menu/TodaysCard/${sportId}`;
      break;
    case URL_TYPE.Antepost.id:
      result = `/Menu/Antepost/${sportId}${categoryId ? `/${categoryId}` : ''}${
        eventId ? `/${eventId}` : ''
      }${marketId ? `/${marketId}` : ''}`;
      break;
    case URL_TYPE.Run.id:
      result = `/Menu/TodaysCard/${sportId}`;
      break;
    case URL_TYPE.Event.id:
      result = `/Menu/Event/${eventId}`;
      break;
    case URL_TYPE.Market.id:
      result = `/Menu/Event/${eventId}/Market/${marketId}`;
      break;
    default:
      result = '';
  }

  // console.log(result);

  return result;
};

export const getMenuEndpoint = (pathname) => {
  const livePrefix = getLivePrefix(pathname);
  return `menu/topSports/${livePrefix}`;
};

/*
 * EVENT ENDPOINTS
 *
 * /Event/top10RacingEvents/{sportId}/{relDayName} - HorseRacingTopEvents
 * /Event/top10TournamentsEvents/{sportId} - getSportsDataEndpoint
 * /Event/topTournamentsEvents/{sportId}
 * /Event/{eventId}/marketDetails/{marketIdentifier} - getSportsDataEndpoint
 * /Event/eventsHighlights/{urlType}/{id} - getSportsDataEndpoint
 * /Event/today/Sport/{sportId}/{page}/{pageSize} - getSportsDataEndpoint
 * /Event/tomorrow/Sport/{sportId}/{page}/{pageSize} - getSportsDataEndpoint
 * /Event/weekend/Sport/{sportId}/{page}/{pageSize} - getSportsDataEndpoint
 * /Event/next7days/Sport/{sportId}/{page}/{pageSize} - getSportsDataEndpoint
 * /Event/inPlay - getSportsDataEndpoint - getSportsDataEndpoint
 * /Event/inPlay/Sport/{sportId}/{page}/{pageSize} - getSportsDataEndpoint
 * /Event/inPlay/Category/{categoryId}/{page}/{pageSize} - getSportsDataEndpoint
 * /Event/inPlay/Tournament/{tournamentId}/{page}/{pageSize} - getSportsDataEndpoint
 *
 */

const getUrlTimeById = (pid) => {
  let res = {};

  Object.keys(URL_TIME).forEach((key) => {
    if (URL_TIME[key].id === Number(pid)) {
      res = URL_TIME[key];
    }
  });

  return res;
};

export const getSportsDataEndpoint = (pathname) => {
  const [
    urlTime,
    urlType,
    sportId,
    categoryId,
    tournamentId,
    eventId,
    marketId,
    ,
    page,
    pageSize,
  ] = getPathnameArray(pathname);

  if (pathname === '' || pathname === '/') {
    return '/Event/top10TournamentsEvents';
  }

  if (Number(eventId)) {
    const mrktStr = Number(marketId)
      ? `/marketDetails/${marketId}`
      : '/marketDetails';
    return `/Event/${eventId}${mrktStr}`;
  }

  if (Number(urlTime) === URL_TIME.LIVE.id) {
    if (!urlType) {
      return '/Event/inPlay';
    }

    const urlParamPage = page || 1;
    const urlParamPageSize = pageSize || 20;
    if (Number(urlType) === URL_TYPE.Sport.id) {
      return `Event/${URL_TIME.LIVE.eventEndpointString}/Sport/${sportId}/${urlParamPage}/${urlParamPageSize}`;
    }

    if (Number(urlType) === URL_TYPE.Category.id) {
      return `Event/${URL_TIME.LIVE.eventEndpointString}/Category/${categoryId}/${urlParamPage}/${urlParamPageSize}`;
    }

    if (Number(urlType) === URL_TYPE.Tornament.id) {
      return `Event/${URL_TIME.LIVE.eventEndpointString}/Tournament/${tournamentId}/${urlParamPage}/${urlParamPageSize}`;
    }
  }

  if (Number(urlTime) > URL_TIME.LIVE.id) {
    const urlTimeItem = getUrlTimeById(urlTime);
    const urlParamPage = page || 1;
    const urlParamPageSize = pageSize || 20;
    return `Event/${urlTimeItem.eventEndpointString}/Sport/${sportId}/${urlParamPage}/${urlParamPageSize}`;
  }

  if (Number(urlType) === URL_TYPE.Category.id) {
    return `/Event/eventsHighlights/Category/${categoryId}`;
  }

  if (Number(urlType) === URL_TYPE.Tornament.id) {
    return `/Event/eventsHighlights/Tournament/${tournamentId}`;
  }

  if (Number(urlType) === URL_TYPE.Sport.id) {
    const urlParamPage = page || 1;
    const urlParamPageSize = pageSize || 20;
    return `/Event/topTournamentsEvents/${sportId}/${urlParamPage}/${urlParamPageSize}`;
  }

  return '';
};

export const getSearchEndpoint = (phrase) => {
  return `/Menu/search?sq=${phrase}`;
};

/*
 *
 *
 * URL STRUCTURE
 * /{urlTime}/{urlType}/{sportId}/{categoryId}/{tournamentId}/{eventId}/{marketId}/{allParam}/{page}/{pageSize}
 * {urlTime} => live, today, tomorrow, weekend, next7days
 * {urlType} => Sport, Category, Tornament, TodaysCard, Antepost, Event, Market, Run
 *
 *
 */
export const getMenuLinkForItem = (item, appStateData = {}) => {
  // {urlTime}/{urlType}/{sportId}/{categoryId}/{tournamentId}/{eventId}/{marketId}/{allParam}/{page}/{pageSize}
  const mrgd = { ...appStateData, ...item };
  // console.log(mrgd);
  const params = [
    mrgd.pageSize,
    mrgd.page,
    mrgd.allParam,
    mrgd.marketId,
    mrgd.eventId,
    mrgd.tournamentId,
    mrgd.categoryId,
    mrgd.sportId,
    mrgd.urlType,
    mrgd.urlTime,
  ];
  let path = '';

  params.forEach((param) => {
    if (path !== '') {
      if (param) {
        path = `/${param}${path}`;
      } else {
        path = `/0${path}`;
      }
    } else if (param) {
      path = `/${param}${path}`;
    }
  });

  if (path === '') {
    path = '/';
  }

  return path;
};

export const prepareMenuItemForLinkGeneration = (item, appStateData = {}) => {
  // console.log(item);
  // console.log(item.path.split('/')[2]);
  if (item.path && item.path.includes('TodaysCard')) {
    return {
      sportId: Number(item.id),
      urlType: URL_TYPE.TodaysCard.id,
    };
  }

  if (item.path && item.path.includes('Antepost')) {
    const pathArr = item.path.split('/');
    pathArr.shift();
    return {
      sportId: Number(pathArr[1]),
      urlType: URL_TYPE.Antepost.id,
      categoryId: Number(pathArr[2] || 0),
      eventId: Number(pathArr[3] || 0),
      marketId: pathArr[4] || null,
    };
  }

  if (item.itemType === 'Sport') {
    if (item.mobi === 'AllSport') {
      return {
        sportId: Number(item.id),
        urlType: URL_TYPE.Sport.id,
        urlTime:
          Number(appStateData.urlTime) === URL_TIME.LIVE.id
            ? appStateData.urlTime
            : URL_TIME.DEFAULT.id,
        allParam: 1,
      };
    }
    return {
      sportId: Number(item.id),
      urlType: URL_TYPE.Sport.id,
      urlTime: appStateData.urlTime,
    };
  }

  if (item.itemType === 'Category') {
    return {
      sportId: Number(item.sportId),
      urlType: URL_TYPE.Category.id,
      categoryId: item.id,
      urlTime:
        Number(appStateData.urlTime) === URL_TIME.LIVE.id
          ? appStateData.urlTime
          : URL_TIME.DEFAULT.id,
    };
  }

  if (item.itemType === 'Tournament') {
    return {
      sportId: Number(item.sportId),
      urlType: URL_TYPE.Tornament.id,
      categoryId: item.categoryId,
      tournamentId: item.id,
      urlTime:
        Number(appStateData.urlTime) === URL_TIME.LIVE.id
          ? appStateData.urlTime
          : URL_TIME.DEFAULT.id,
    };
  }

  if (item.itemType === 'Event') {
    return {
      sportId: Number(item.sportId),
      urlType: URL_TYPE.Event.id,
      categoryId: item.categoryId,
      tournamentId: item.tournamentId,
      eventId: item.id,
      urlTime:
        Number(appStateData.urlTime) === URL_TIME.LIVE.id
          ? appStateData.urlTime
          : URL_TIME.DEFAULT.id,
    };
  }

  if (item.itemType === 'Market') {
    let uType = URL_TYPE.Market.id;
    if (Number(appStateData.urlType) === URL_TYPE.TodaysCard.id) {
      uType = URL_TYPE.TodaysCard.id;
    }
    const res = {
      // sportId: Number(item.sportId),
      urlType: uType,
      // categoryId: item.categoryId,
      // tournamentId: item.tournamentId,
      eventId: item.eventId,
      marketId: item.identifier,
      // urlTime: appStateData.urlTime,
    };
    let evId = item.eventId;
    const ptArr = item.path.split('/');
    if (!evId) {
      // console.log('noeventid');
      // console.log(item);
      evId = ptArr[ptArr.indexOf('Event') + 1] || ptArr[2];
    }
    if (evId) {
      res.eventId = evId;
    }
    return res;
  }

  if (item.itemType === 'Run') {
    return {
      sportId: Number(item.sportId) || appStateData.sportId,
      urlType: appStateData.urlType,
      categoryId: item.categoryId || appStateData.categoryId,
      tournamentId: item.tournamentId || appStateData.tournamentId,
      eventId: item.eventId || item.path.split('/')[2],
      marketId: item.identifier,
      urlTime: appStateData.urlTime,
    };
  }

  if (item.itemType === 'TimeSlice') {
    let timeItem = {};
    Object.keys(URL_TIME).forEach((key) => {
      if (URL_TIME[key].itemName === item.name) {
        timeItem = URL_TIME[key];
      }
    });

    if (Object.hasOwn(timeItem, 'id')) {
      return {
        urlTime: timeItem.id,
        sportId: appStateData.sportId,
        urlType: URL_TYPE.Sport.id,
      };
    }
  }

  return item;
};
