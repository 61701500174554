import { createSlice } from '@reduxjs/toolkit';

import Api from 'api';

const initialState = {
  balance: 0,
  liability: 0,
  currencyCode: '',
  showCurrecySign: true,
  isActive: true,
  smToken: '',
  partnerConnected: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    balanceAndLiabilityLoaded: (state, action) => {
      const { balance, liability } = action.payload;
      state.balance = balance;
      state.liability = liability;
    },
    defaultCurrencyLoaded: (state, action) => {
      const { currencyCode, showCurrecySign } = action.payload;
      state.currencyCode = currencyCode;
      state.showCurrecySign = !!showCurrecySign;
      window.showCurrecySign = !!showCurrecySign;
    },
    setUserIsActive: (state, action) => {
      state.isActive = action.payload;
    },
    setSmToken: (state, action) => {
      state.smToken = action.payload;
    },
    setPartnerConnected: (state, action) => {
      state.partnerConnected = action.payload;
    },
  },
});

export const {
  balanceAndLiabilityLoaded,
  defaultCurrencyLoaded,
  setUserIsActive,
  setSmToken,
  setPartnerConnected,
} = userSlice.actions;
export const userReducer = userSlice.reducer;

export const fetchUserBalanceAndLiability = () => async (dispatch) => {
  try {
    const { data } = await Api.get(`user/balanceAndLiability`);

    dispatch(balanceAndLiabilityLoaded(data));
  } catch (err) {
    console.error(err);
  }
};

export const fetchDefaultCurrency = () => async (dispatch) => {
  try {
    const { data } = await Api.get(`user/defaultCurrency`);

    dispatch(defaultCurrencyLoaded(data));
  } catch (err) {
    console.error(err);
  }
};

export const setUserIsActiveState = (isActive) => (dispatch) => {
  dispatch(setUserIsActive(isActive));
};

export const setSmTokenValue = (token) => (dispatch) => {
  console.log(token);
  dispatch(setSmToken(token));
};

export const setPartnerConnectedState = (val) => (dispatch) => {
  dispatch(setPartnerConnected(val));
};
