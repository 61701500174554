import { setLiveTrackerData } from 'slices/ws';

export class Ws {
  constructor() {
    this.url = '';
    this.socket = null;
  }

  connect(url) {
    if (!this.socket) {
      this.socket = new WebSocket(url);
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }
  }

  send(message) {
    if (this.socket) {
      this.socket.send(JSON.stringify(message));
    }
  }

  on(eventName, callback) {
    if (this.socket) {
      this.socket.addEventListener(eventName, callback);
    }
  }
}

export const socketMiddleware = (socket) => (params) => (next) => (action) => {
  const { dispatch, getState } = params;
  const { type, message } = action;

  switch (type) {
    case 'socket/connect':
      socket.connect('wss://ws.oddsdealers.com:5222/smevents');

      socket.on('open', () => {});
      socket.on('message', (data) => {
        const store = getState();
        try {
          const jsonMsg = JSON.parse(data.data);
          const currentData = { ...store.websocket.liveTrackerData };
          if (jsonMsg.msgt && jsonMsg.msgt === 'SMU' && jsonMsg.EID) {
            if (currentData[jsonMsg.EID]) {
              currentData[jsonMsg.EID] = {
                ...currentData[jsonMsg.EID],
                ...jsonMsg.msgv,
              };
            } else {
              currentData[jsonMsg.EID] = jsonMsg.msgv;
            }
          }
          dispatch(setLiveTrackerData(currentData));
        } catch (e) {
          console.log(`Could not parse WebSocket message: ${data.data}`);
        }

        // console.log(data);
        // console.log(store);
      });
      socket.on('close', () => {});
      break;

    case 'socket/disconnect':
      socket.disconnect();
      break;

    case 'socket/send':
      socket.send(message);
      break;

    default:
      break;
  }

  return next(action);
};
