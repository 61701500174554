import { createSlice } from '@reduxjs/toolkit';

export const BET_TYPES = {
  LAY: 'lay',
  BACK: 'back',
};

const DEFAULT_BET = {
  id: '',
  name: '',
  columnName: '',
  price: '',
  size: '',
  stake: '',
};

export const BET_STATES = {
  default: 0,
  waiting: 1,
  confirmed: 2,
  processing: 3,
  processed: 4,
};

const initialState = {
  data: [],
};

const validationFunction = (item) => item.price && item.stake;

export const betsSlice = createSlice({
  name: 'bets',
  initialState,
  reducers: {
    addBet: (state, action) => {
      const bet = action.payload;
      const { data } = state;

      const isAdded = data.find(
        (b) => b.id === bet.id && b.betType === bet.betType
      );

      state.data = !isAdded
        ? [...state.data, bet]
        : data.map((item) => {
            const it = { ...item };
            if (it.id === bet.id && it.betType === bet.betType) {
              delete it.placementResult;
              return { ...it, ...bet };
            }
            return it;
          });
    },
    removeBet: (state, action) => {
      const { id, type } = action.payload;
      state.data = state.data.filter(
        (bet) => bet.id !== id || bet.betType !== type
      );
    },
    clearBets: (state) => {
      state.data = [];
    },
    setBetState: (state, action) => {
      const { bet, betState } = action.payload;
      const { data } = state;

      state.data = data.map((item) => {
        const it = { ...item };
        if (it.id === bet.id && it.betType === bet.betType) {
          it.betState = betState;
          return it;
        }
        return it;
      });
    },
  },
});

export const { addBet, removeBet, clearBets, setBetState } = betsSlice.actions;

export const betsReducer = betsSlice.reducer;

export const addBetByType = (betType, bet) => (dispatch) => {
  const newBet = { ...DEFAULT_BET, ...bet, betType };
  delete newBet.placementResult;
  dispatch(addBet(newBet));
};

export const updateBetData = (betUpdate, bet) => (dispatch) => {
  dispatch(addBet({ ...{ placementResult: { ...betUpdate } }, ...bet }));
};

export const isBetsValidSelector = (state) => {
  const layBets = state.bets.data.filter(
    (bet) => bet.betType === BET_TYPES.LAY
  );
  const backBets = state.bets.data.filter(
    (bet) => bet.betType === BET_TYPES.BACK
  );

  const isOneBetExist = !!layBets.length || !!backBets.length;

  const isLayBetsValid = layBets.length
    ? layBets.every(validationFunction)
    : true;
  const isBackBetsValid = backBets.length
    ? backBets.every(validationFunction)
    : true;

  const hasBetPlacementResults = state.bets.data.find(
    (bet) => bet.placementResult
  );

  return (
    isLayBetsValid &&
    isBackBetsValid &&
    isOneBetExist &&
    !hasBetPlacementResults
  );
};
