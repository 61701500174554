import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  betSlipPanelState: 0,
  sideMenuPanelState: 0,
  layoutType: 'default',
  eventViewColumnsCount: 2,
  pageLength: 20,
  userAcountMenuState: '',
  modalStore: [],
  seamlessLayout: {},
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setBetSlipPanelState: (state, action) => {
      state.betSlipPanelState = action.payload;
    },
    setSideMenuPanelState: (state, action) => {
      state.sideMenuPanelState = action.payload;
    },
    setLayoutType: (state, action) => {
      state.layoutType = action.payload;
    },
    setEventViewColumnsCount: (state, action) => {
      state.eventViewColumnsCount = action.payload;
    },
    setUserAccountMenuState: (state, action) => {
      state.userAcountMenuState = action.payload;
    },
    addItemToModalStore: (state, action) => {
      state.modalStore.push(action.payload);
    },
    removeItemFromModalStore: (state) => {
      if (state.modalStore.length) {
        state.modalStore.pop();
      }
    },
    setSeamlessLayout: (state, action) => {
      console.log(action.payload);
      state.seamlessLayout = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setBetSlipPanelState,
  setSideMenuPanelState,
  setLayoutType,
  setEventViewColumnsCount,
  setUserAccountMenuState,
  addItemToModalStore,
  removeItemFromModalStore,
  setSeamlessLayout,
} = layoutSlice.actions;

export const layoutReducer = layoutSlice.reducer;
