import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setLayoutType, setEventViewColumnsCount } from 'slices/layout';
import { useProcessMessageFromHost } from 'utils/sdk-client';
import { isSeamless } from 'appConfiguration';
import { Routing } from './routing';

export function App() {
  const dispatch = useDispatch();
  useProcessMessageFromHost(isSeamless);
  const manageLayoutByWindowSize = useCallback(() => {
    if (window.innerWidth <= 600) {
      dispatch(setEventViewColumnsCount(1));
    } else {
      dispatch(setEventViewColumnsCount(2));
    }
    if (window.innerWidth <= 1024) {
      dispatch(setLayoutType('mobile'));
    } else {
      dispatch(setLayoutType('default'));
    }
  }, [dispatch]);

  useEffect(() => {
    manageLayoutByWindowSize();
    window.addEventListener('resize', manageLayoutByWindowSize);
  }, [manageLayoutByWindowSize]);

  useEffect(() => {
    dispatch({ type: 'socket/connect' });

    return () => {
      dispatch({ type: 'socket/disconnect' });
    };
  }, [dispatch]);

  return <Routing />;
}
