import { utcToZonedTime, format } from 'date-fns-tz';
import { getTranslated } from './translator';

export const roundTo2Decimal = (v) => Math.round(v * 100) / 100;

export const parseDatetimeString = (dateString) => {
  return `${dateString}.000Z`; // Z = UTC
};

export const createDateAsUTC = (dateString) => {
  return new Date(parseDatetimeString(dateString));
};

export const getDateTimeObject = (dateString, timezone) => {
  const utcDate = createDateAsUTC(dateString);
  const dateTz = utcToZonedTime(utcDate, timezone);
  const newDate = format(dateTz, 'MMM dd');
  const newTime = format(dateTz, 'HH:mm');
  return { date: newDate, time: newTime };
};

export const getEventDateTime = (dateString, timezone) => {
  const utcDate = dateString ? createDateAsUTC(dateString) : new Date();
  const dateWithTz = utcToZonedTime(utcDate, timezone);
  return format(dateWithTz, 'EEE, MMM dd, yyyy HH:mm');
};

export const parseCurrency = (options) => {
  const missingValue = options.missingValue ? options.missingValue : '';

  if (!options.sum && !options.useZeroForMissingVal) {
    return missingValue;
  }
  if (!options.currencyCode) {
    return options.sum;
  }
  if (options.useMathFloor && Math.floor(options.sum) === 0) {
    return missingValue;
  }
  const { currencyCode } = options;
  const locale = options.locale
    ? options.locale
    : Intl.DateTimeFormat().resolvedOptions().locale;
  const formatterDefaultOpts = window.showCurrecySign
    ? { style: 'currency', currency: currencyCode }
    : { minimumFractionDigits: 2, maximumFractionDigits: 2 };
  const formatterOpts = {
    ...formatterDefaultOpts,
    ...options.additionalOptions,
  };
  return Intl.NumberFormat(locale, formatterOpts).format(options.sum);
};

export const httpRqErrorParser = (error, unhandled = null) => {
  if (error.response.data.errors) {
    if (Array.isArray(error.response.data.errors)) {
      const res = { ...error };

      if (
        error.response.data.errors[0].toString().includes('Max bet exceeded')
      ) {
        res.response.data.errors[0] = getTranslated('httpRqErrorMaxBet', [
          error.response.data.maxBet,
        ]);
      }

      if (
        error.response.data.errors[0]
          .toString()
          .includes('Max exposure exceeded')
      ) {
        res.response.data.errors[0] = getTranslated('httpRqErrorMaxExposure', [
          error.response.data.maxExposure,
        ]);
      }

      return res.response.data;
    }
    return error.response.data;
  }

  if (unhandled) {
    return {
      success: false,
      errors: unhandled,
    };
  }
  return {
    success: false,
    errors: [error.message],
  };
};
