import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  locale: Intl.DateTimeFormat().resolvedOptions().locale,
};

export const localsSlice = createSlice({
  name: 'locals',
  initialState,
});

export const localsReducer = localsSlice.reducer;
