import axios from 'axios';

import { AuthService } from './services/AuthService';

const instance = axios.create({
  /* eslint no-underscore-dangle: ["error", { "allow": ["__RUNTIME_CONFIG__"] }] */
  baseURL:
    window?.__RUNTIME_CONFIG__?.REACT_APP_BASE_API ??
    process.env.REACT_APP_BASE_API,
  timeout: 0, // was 5000 for 5 sec.
});

instance.interceptors.request.use((req) => {
  const accessToken = AuthService.userToken();

  req.headers.Authorization = `Bearer ${accessToken}`;
  return req;
});

/**
 * For 401 - Redirect to login page.
 */
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response?.status;

    if (status === 401) {
      AuthService.setUserToken('');
      window.location.href = `${window.location.origin}/login`;
    }

    return Promise.reject(error);
  }
);

// eslint-disable-next-line import/no-default-export
export default instance;
