export const sseMarketsConnect = (host, eventIds) => ({
  type: 'SSE_MARKETS_CONNECT',
  host,
  eventIds,
});
export const sseMarketsConnected = (host) => ({
  type: 'SSE_MARKETS_CONNECTED',
  host,
});
export const sseMarketsDisconnect = (host) => ({
  type: 'SSE_MARKETS_DISCONNECT',
  host,
});
export const sseMarketsDisconnected = (host) => ({
  type: 'SSE_MARKETS_DISCONNECTED',
  host,
});

export const sseOrdersConnect = (host, eventIds) => ({
  type: 'SSE_ORDERS_CONNECT',
  host,
  eventIds,
});
export const sseOrdersConnected = (host) => ({
  type: 'SSE_ORDERS_CONNECTED',
  host,
});
export const sseOrdersDisconnect = (host) => ({
  type: 'SSE_ORDERS_DISCONNECT',
  host,
});
export const sseOrdersDisconnected = (host) => ({
  type: 'SSE_ORDERS_DISCONNECTED',
  host,
});
