/*
 { v: long? Version
 { t: double? TotalMatched
 { st: string Status
 { rv: bool? RunnersVoidable
 { rs: List<Runner> Runners
 { nw: int? NumberOfWinners
 { na: int? NumberOfActiveRunners
 { ix: int MarketId
 { mi: string MarketIdentifier
 { ei: string EventIdentifier
 { eti: string EventTypeIdentifier
 { mti: string MarketTypeIdentifier
 { pl: bool? Inplay
 { cm: bool? CrossMatching
 { c: bool? Complete
 { r: bool? BspReconciled
 { d: int? BetDelay

*/

export const sseContractDictionary = [
  // Market Update
  { v: 'Version' },
  { t: 'TotalMatched' },
  { st: 'Status' },
  { rv: 'RunnersVoidable' },
  { rs: 'Runners' },
  { nw: 'NumberOfWinners' },
  { na: 'NumberOfActiveRunners' },
  { ix: 'MarketId' },
  { mi: 'MarketIdentifier' },
  { ei: 'EventIdentifier' },
  { eti: 'EventTypeIdentifier' },
  { mti: 'MarketTypeIdentifier' },
  { pl: 'Inplay' },
  { cm: 'CrossMatching' },
  { c: 'Complete' },
  { r: 'BspReconciled' },
  { d: 'BetDelay' },
  // Runner
  { o: 'SortPriority' },
  { si: 'SelectionId' },
  { lpt: 'LastPriceTraded' },
  { h: 'Handicap' },
  { ps: 'Prices' },
  // Prices
  { atb: 'AvailableToBack' },
  { atl: 'AvailableToLay' },
  // PriceSizePair
  { s: 'Size' },
  { p: 'Price' },
];

const correctProperty = (key, dict) => {
  // console.log(key);
  const res = { status: false, correctKey: key };
  if (Array.isArray(dict)) {
    dict.forEach((item) => {
      if (typeof item === 'object' && item[key]) {
        res.status = true;
        res.correctKey = item[key];
      }
    });
  }
  return res;
};

const dictTranslator = (data, dict, clearTranslated) => {
  if (typeof data !== 'object') {
    return data;
  }
  const pData = data;
  if (Array.isArray(pData)) {
    pData.forEach((item) => {
      dictTranslator(item, dict, clearTranslated);
    });
  }

  Object.keys(pData).forEach((key) => {
    if (Array.isArray(pData[key])) {
      // console.log('arr: ');
      // console.log(data[key]);
      dictTranslator(pData[key], dict, clearTranslated);
    }
    if (
      pData[key] &&
      typeof pData[key] === 'object' &&
      !Array.isArray(pData[key])
    ) {
      // console.log('obj: ');
      // console.log(data[key]);
      // console.log(data);
      dictTranslator(pData[key], dict, clearTranslated);
    }
    const doIt = correctProperty(key, dict);
    if (doIt.status) {
      pData[doIt.correctKey] = pData[key];
      if (doIt.correctKey && key !== doIt.correctKey && clearTranslated) {
        delete pData[key];
      }
    }
  });

  return pData;
};

export const translator = ({
  data,
  type = 0,
  dictionary = [],
  // structure,
  clearTranslated = false,
}) => {
  if (type === 0) {
    return dictTranslator(data, dictionary, clearTranslated);
  }

  return data;
};
