import { createSlice } from '@reduxjs/toolkit';

import Api from 'api';
import { getSearchEndpoint } from 'utils/urlHandlers';

const initialState = {
  searchString: '',
  searchPhrase: '',
  searchResults: [],
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    updateSearchString: (state, action) => {
      state.searchString = action.payload;
    },
    updateSearchPhrase: (state, action) => {
      state.searchPhrase = action.payload;
    },
    updateSearchResults: (state, action) => {
      state.searchResults = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateSearchString, updateSearchResults, updateSearchPhrase } =
  searchSlice.actions;

export const searchReducer = searchSlice.reducer;

export const fetchSearchResults = (phrase) => async (dispatch) => {
  const route = getSearchEndpoint(phrase);

  if (route && phrase.length > 2) {
    const { data: res } = await Api.get(route);
    const results = res.searchResult;

    if (results) {
      dispatch(updateSearchResults(results));
    } else {
      dispatch(updateSearchResults([]));
    }
  } else {
    dispatch(updateSearchResults([]));
  }
};
