import translationData from '../translationsData/translation.json';
import { langCode } from '../appConfiguration';

const translationJsonObject = translationData;

function replacePlaceholders(text, args) {
  return text.replace(/\{\{(\d+)\}\}/g, (match, index) => {
    const replacement = args[index] !== undefined ? args[index] : match;
    return replacement;
  });
}

export function getTranslated(phrase, args) {
  const langObj = translationJsonObject.find((obj) => obj.phrase === phrase);

  if (langObj) {
    if (!langCode || langCode === '') {
      // checking if langCode is provided! If not then it will use the default language from the json.
      return `${phrase}`;
    }
    const translationText = langObj[langCode];
    if (translationText) {
      // checking is we have available  translation for given language code if not we will just return the phrase.
      if (args && args.length !== 0) {
        // checking if arguments are provided if not we will return the text with placeholder "{{{0}}}, {{{1}}} etc."
        return replacePlaceholders(translationText, args);
      }
      return `${translationText}`;
    }
    return `${phrase}`;
  }
  return `${phrase}`;
}
